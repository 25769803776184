import { PropsWithChildren, ReactElement } from 'react';
import AgentReportProvider from './AgentReportProvider';
import QueueReportsProvider from './QueueReportsProvider';
import SignalRConnectionProvider from './SignalRConnectionProvider';
import TeamReportsProvider from './TeamReportsProvider';
import WaitingConversationsProvider from './WaitingConversationsProvider';
import WebsiteVisitorsProvider from './WebsiteVisitorsProvider';

/**
 * Contains all necessary providers for accessing the reporting data.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 */
export default function ReportingDataProviders({ children }: PropsWithChildren): ReactElement {

  return (
    <SignalRConnectionProvider>
      <QueueReportsProvider>
        <WaitingConversationsProvider>
          <TeamReportsProvider>
            <AgentReportProvider>
              <WebsiteVisitorsProvider>
                {children}
              </WebsiteVisitorsProvider>
            </AgentReportProvider>
          </TeamReportsProvider>
        </WaitingConversationsProvider>
      </QueueReportsProvider>
    </SignalRConnectionProvider>
  );
}