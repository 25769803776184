import { useLazyUpdateState } from '@buzzeasy/shared-frontend-utilities';
import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthInfo } from '../../../redux/authSlice';
import { defaultAgentReport, processAgentLiveData, processAgentTodayData, processWorkItemData } from '../../calculations/teamCalculations';
import { AgentReport, RawAgentLiveData, RawAgentTodayData, RawWorkItemData } from '../../models/reports.agents';
import { SignalRConnectionContext } from './SignalRConnectionProvider';

export const AgentReportContext = createContext<AgentReport>(defaultAgentReport(''));

export default function AgentReportProvider({ children }: PropsWithChildren): ReactElement {
  const { subscribe, unsubscribe } = useContext(SignalRConnectionContext);
  const { user: { id: userId } } = useSelector(selectAuthInfo);

  const [agentReport, setAgentReport] = useLazyUpdateState<AgentReport>(defaultAgentReport(userId));

  const receiveTodaysAgentData = useCallback(
    (data: RawAgentTodayData) => {
      if (data.id !== userId)
        return;

      setAgentReport(curr => ({ ...curr, ...processAgentTodayData(data) }));
    },
    [setAgentReport, userId],
  );

  const receiveLiveAgentData = useCallback(
    (data: RawAgentLiveData[]) => {
      const agentData = data.find(a => a.agentId === userId);

      if (!agentData)
        return;

      setAgentReport(curr => ({ ...curr, ...processAgentLiveData(agentData) }));
    },
    [setAgentReport, userId],
  );

  const receiveWorkItemData = useCallback(
    (data: RawWorkItemData[]) => {
      const currentlyHandledWorkItems = data
        .filter(wi => wi.agentId === userId)
        .map(processWorkItemData);

      if (currentlyHandledWorkItems.length > 0)
        setAgentReport(curr => ({ ...curr, currentlyHandledWorkItems }));
    },
    [setAgentReport, userId],
  );

  useEffect(
    () => {
      const subId = subscribe({ receiveTodaysAgentData, receiveLiveAgentData, receiveWorkItemData });
      return () => unsubscribe(subId);
    },
    [receiveLiveAgentData, receiveTodaysAgentData, receiveWorkItemData, subscribe, unsubscribe],
  );

  return (
    <AgentReportContext.Provider value={agentReport}>
      {children}
    </AgentReportContext.Provider>
  );
}