import { getItem, setItem } from '@buzzeasy/shared-frontend-utilities';
import { PropsWithChildren, ReactElement, createContext, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthInfo } from '../../../redux/authSlice';

const maxFavoriteCount = 5;
const favoritesKey = 'aui-favoriteReports';

interface FavoriteReportsProps {
  isFavorite(id: string): boolean;
  setIsFavorite(id: string, favorite: boolean): void;
  favorites: string[];
}

export const FavoriteReportsContext = createContext<FavoriteReportsProps>({ isFavorite: () => false, setIsFavorite: () => { /* empty */ }, favorites: [] });

/**
 * Provides the value for FavoriteReportsContext.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 */
export default function FavoriteReportsProvider({ children }: PropsWithChildren): ReactElement {
  const { tenantId } = useSelector(selectAuthInfo);
  const [favorites, setFavorites] = useState(getItem<Record<string, string[] | undefined>>(favoritesKey, {}));

  const tenantFavorites = useMemo(
    () => favorites[tenantId] ?? [],
    [favorites, tenantId],
  );

  const isFavorite = useCallback<FavoriteReportsProps['isFavorite']>(
    (id) => favorites[tenantId]?.includes(id) ?? false,
    [favorites, tenantId],
  );

  const setIsFavorite = useCallback<FavoriteReportsProps['setIsFavorite']>(
    (id, favorite) => {
      let updatedFavorites = [...(favorites[tenantId] ?? [])];

      if (favorite) {
        if (updatedFavorites.length >= maxFavoriteCount)
          updatedFavorites.shift();

        updatedFavorites.push(id);
      }
      else {
        updatedFavorites = updatedFavorites.filter(f => f !== id);
      }

      const updatedAllFavorites = { ...favorites, [tenantId]: updatedFavorites };
      setItem(favoritesKey, updatedAllFavorites);
      setFavorites(updatedAllFavorites);
    },
    [favorites, tenantId],
  );

  return (
    <FavoriteReportsContext.Provider value={{ isFavorite, setIsFavorite, favorites: tenantFavorites }}>
      {children}
    </FavoriteReportsContext.Provider>
  );
}