import { reduceWithSum } from '../../utils/arrayUtils';
import { MediaType, MediaTypes, ReportingAgentStates } from '../models/reports';
import { AgentReport, TeamReport, WorkItemHandlingStats } from '../models/reports.agents';
import { Team } from '../models/team';
import { defaultAgentReport } from './teamCalculations';

function getHandlingStatByMedia(members: AgentReport[], media: MediaType): WorkItemHandlingStats {
  const handled = reduceWithSum(members, (ar) => ar.workItemsByMedia[media].handled);
  const missed = reduceWithSum(members, (ar) => ar.workItemsByMedia[media].missed);
  const sumHandlingTime = reduceWithSum(members, (ar) => ar.workItemsByMedia[media].avgHandlingTime * ar.workItemsByMedia[media].handled);

  return {
    handled,
    missed,
    avgHandlingTime: Math.floor(sumHandlingTime / (handled || 1)),
  };
}

export function aggregateAgentProperties(agents: AgentReport[], selector: (agent: AgentReport) => Record<string, number>): Record<string, number> {
  const collector: Record<string, number> = agents.length > 0 ? {} : selector(defaultAgentReport(''));

  agents.forEach(a =>
    Object.entries(selector(a))
      .map(([key, value]) => { collector[key] = (collector[key] ?? 0) + value; }),
  );

  return collector;
}

export default function aggregateAgentsIntoTeam(team: Team, agents: AgentReport[]): TeamReport {
  const members = agents.filter(a => team.memberIds.includes(a.id));

  return {
    id: team.id,
    name: team.name,
    memberIds: team.memberIds,
    memberReports: members,
    currentMemberAvailability: ReportingAgentStates.reduce((coll, state) => ({ ...coll, [state]: members.filter(m => m.currentState === state).length }), {} as TeamReport['currentMemberAvailability']),
    timeSpentInStates: aggregateAgentProperties(members, m => m.timeSpentInStates),
    timeSpentInBreakNames: aggregateAgentProperties(members, m => m.timeSpentInBreakNames),
    workItemsByMedia: MediaTypes.reduce((coll, media) => ({ ...coll, [media]: getHandlingStatByMedia(members, media) }), {} as TeamReport['workItemsByMedia']),
    workItemsByDispositionCode: aggregateAgentProperties(members, m => m.workItemsByDispositionCode),
  };
}