import { CustomerConnection, CustomerSiteInteraction } from '../../models/websiteVistitors';

export interface RawCustomerConnection {
  clientId: string;
  channelId?: string;
  siteInteraction: RawCustomerSiteInteraction;
}

export interface RawCustomerSiteInteraction {
  pageTitle: string;
  url: string;
  urlVisitedAt: string;
  siteOpenedAt: string;
}

export function mapToInternalSiteInteraction(i: RawCustomerSiteInteraction): CustomerSiteInteraction {
  return {
    ...i,
    urlVisitedAt: new Date(i.urlVisitedAt),
    siteOpenedAt: new Date(i.siteOpenedAt),
  };
}

export function mapToInternalCustomerConnection(c: RawCustomerConnection): CustomerConnection {
  return {
    ...c,
    siteInteraction: mapToInternalSiteInteraction(c.siteInteraction),
  };
}