import { useLazyUpdateState } from '@buzzeasy/shared-frontend-utilities';
import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { defaultQueueReport, processQueueLiveData, processQueueTodayData } from '../../calculations/queueCalculations';
import { ReportDictionary } from '../../models/reports';
import { QueueReport, RawQueueLiveData, RawQueueTodayData } from '../../models/reports.queues';
import { SignalRConnectionContext } from './SignalRConnectionProvider';

function get(dictionary: ReportDictionary<QueueReport>, id: string): QueueReport {
  return dictionary[id] ?? defaultQueueReport(id);
}

export const QueueReportsContext = createContext<QueueReport[]>([]);

export default function QueueReportsProvider({ children }: PropsWithChildren): ReactElement {
  const { subscribe, unsubscribe } = useContext(SignalRConnectionContext);

  const [queuesDict, setQueuesDict] = useLazyUpdateState<ReportDictionary<QueueReport>>({});
  const queuesDictRef = useRef(queuesDict);
  queuesDictRef.current = queuesDict;

  const receiveTodaysQueueData = useCallback(
    (data: RawQueueTodayData) => setQueuesDict(dict => {
      const queue = get(dict, data.id);
      const processed = processQueueTodayData(data);
      const merged: QueueReport = { ...queue, ...processed };

      return { ...dict, [merged.id]: merged };
    }),
    [setQueuesDict],
  );

  const receiveLiveQueueData = useCallback(
    (data: RawQueueLiveData) => setQueuesDict(dict => {
      const queue = get(dict, data.id);
      const processed = processQueueLiveData(data);
      const merged: QueueReport = { ...queue, ...processed };

      return { ...dict, [merged.id]: merged };
    }),
    [setQueuesDict],
  );

  useEffect(
    () => {
      const subId = subscribe({ receiveTodaysQueueData, receiveLiveQueueData });
      return () => unsubscribe(subId);
    },
    [receiveLiveQueueData, receiveTodaysQueueData, subscribe, unsubscribe],
  );

  const [queueReports, setQueueReports] = useLazyUpdateState<QueueReport[]>([]);

  useEffect(
    () => {
      const interval = setInterval(
        () => setQueueReports(Object.values(queuesDictRef.current)),
        3000,
      );

      return () => clearInterval(interval);
    },
    [setQueueReports],
  );

  return (
    <QueueReportsContext.Provider value={queueReports}>
      {children}
    </QueueReportsContext.Provider>
  );
}