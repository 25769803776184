import { ReactElement, lazy } from 'react';
import GlobalStyle from '../../GlobalStyle';
import ComponentErrorBoundary from '../../components/ComponentErrorBoundary';
import PageTitleAndIconUpdater from '../../components/PageTitleAndIconUpdater';
import Suspended from '../../components/Suspended';
import AppLayout from '../../components/layouts/AppLayout';
import Providers from './Providers';

const LazyAppBar = lazy(() => import('./components/containers/AppBar'));
const LazyMultiTaskDisplay = lazy(() => import('./taskHandling/containers/MultiTaskDisplay'));

export default function App(): ReactElement {

  return (
    <Providers>
      <GlobalStyle />
      <PageTitleAndIconUpdater />
      <ComponentErrorBoundary allowPageRefresh>
        <AppLayout
          header={<Suspended noFallback component={LazyAppBar} />}
          content={<Suspended component={LazyMultiTaskDisplay} />}
        />
      </ComponentErrorBoundary>
    </Providers>
  );
}
