import { setVersionInfo } from '@buzzeasy/shared-frontend-utilities';
import ReactDOM from 'react-dom/client';
import environmentConfig from '../../environmentConfig';
import globalSetup from '../../globalSetup';
import App from './App';

import '@buzzeasy/shared-frontend-utilities/extensions';

setVersionInfo('agent-ui-host', environmentConfig.buildNumber);
globalSetup();

// Vite needs the '/' at the end to consider the path a separate page.
// So this is catch-and-fallback to the actual CRM page for a better user experience
if (window.location.pathname === '/crm')
  window.location.replace('/crm/');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
