/* eslint-disable @typescript-eslint/no-explicit-any */

export function reduceWithSum(arr: number[]): number;
export function reduceWithSum<T>(arr: T[], selector: (curr: T) => number): number;
export function reduceWithSum(arr: any[], selector?: (curr: any) => number): number {
  const get = selector ?? ((curr) => curr);
  return arr.reduce((sum, curr) => sum + get(curr), 0);
}

export function groupByProperty<T>(arr: T[], selectKey: (item: T) => PropertyKey): Record<PropertyKey, T[]> {
  return arr.reduce(
    (coll, curr) => {
      const key = selectKey(curr);
      if (!(key in coll))
        coll[key] = [];

      coll[key].push(curr);
      return coll;
    },
    {} as Record<PropertyKey, T[]>,
  );
}