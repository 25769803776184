import { PropsWithChildren, ReactElement } from 'react';
import BaseProviders from '../../components/providers/BaseProviders';
import FavoriteReportsProvider from '../../dashboard/components/providers/FavoriteReportsProvider';
import ReportingDataProviders from '../../dashboard/components/providers/ReportingDataProviders';

/**
 * Providers for the Main app.
 */
export default function Providers({ children }: PropsWithChildren): ReactElement {

  return (
    <BaseProviders>
      <ReportingDataProviders> {/* depends on AuthProvider */}
        <FavoriteReportsProvider>
          {children}
        </FavoriteReportsProvider>
      </ReportingDataProviders>
    </BaseProviders>
  );
}