import { useLazyUpdateState } from '@buzzeasy/shared-frontend-utilities';
import { Method, SignalRHub } from '@buzzeasy/shared-frontend-utilities/signalr';
import { PropsWithChildren, ReactElement, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import environmentConfig from '../../../environmentConfig';
import { useGetMyTeamsQuery } from '../../../redux/apis/teamsApi';
import { selectAuthInfo } from '../../../redux/authSlice';
import { RawAgentLiveData, RawAgentTodayData, RawWorkItemData } from '../../models/reports.agents';
import { RawQueueLiveData, RawQueueTodayData } from '../../models/reports.queues';
import { Team } from '../../models/team';

const { apiBaseUrl } = environmentConfig;

interface Handlers {
  receiveTodaysQueueData(data: RawQueueTodayData): void;
  receiveLiveQueueData(data: RawQueueLiveData): void;
  receiveTodaysAgentData(data: RawAgentTodayData): void;
  receiveLiveAgentData(data: RawAgentLiveData[]): void;
  receiveWorkItemData(data: RawWorkItemData[]): void;
}

interface ContextValue {
  subscribe(handlers: Partial<Handlers>): string;
  unsubscribe(subscriberId: string): void;
  teams: Team[];
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SignalRConnectionContext = createContext<ContextValue>(undefined!);

export default function SignalRConnectionProvider({ children }: PropsWithChildren): ReactElement {
  const { token, user: { id: userId } } = useSelector(selectAuthInfo);

  const { data: foundTeams } = useGetMyTeamsQuery(undefined, { pollingInterval: 60000 });

  const [teams, setTeams] = useLazyUpdateState<Team[]>([]);
  useEffect(
    () => { setTeams(foundTeams ?? []); },
    [foundTeams, setTeams],
  );

  const queueIds = useMemo(
    () => Array.from(new Set(teams.flatMap(t => t.queueIds))),
    [teams],
  );

  const [subscribers, setSubscribers] = useState<Record<string, Partial<Handlers>>>({});

  // subscribersRef used not to tear down and rebuild SignalR connection every time on subscriber change
  const subscribersRef = useRef(subscribers);
  subscribersRef.current = subscribers;

  const subscribe = useCallback(
    (handlers: Partial<Handlers>) => {
      const subscriberId = uuidV4();
      setSubscribers(curr => ({ ...curr, [subscriberId]: handlers }));
      return subscriberId;
    },
    [],
  );

  const unsubscribe = useCallback(
    (subscriberId: string) => setSubscribers(curr => {
      const newSubscribers = { ...curr };
      delete newSubscribers[subscriberId];
      return newSubscribers;
    }),
    [],
  );

  const receiveTodaysQueueData = useCallback(
    (data: RawQueueTodayData) => Object.values(subscribersRef.current).forEach(handlers => handlers.receiveTodaysQueueData?.(data)),
    [],
  );

  const receiveLiveQueueData = useCallback(
    (data: RawQueueLiveData) => Object.values(subscribersRef.current).forEach(handlers => handlers.receiveLiveQueueData?.(data)),
    [],
  );

  const receiveTodaysAgentData = useCallback(
    (data: RawAgentTodayData) => Object.values(subscribersRef.current).forEach(handlers => handlers.receiveTodaysAgentData?.(data)),
    [],
  );

  const receiveLiveAgentData = useCallback(
    (data: RawAgentLiveData[]) => Object.values(subscribersRef.current).forEach(handlers => handlers.receiveLiveAgentData?.(data)),
    [],
  );

  const receiveWorkItemData = useCallback(
    (data: RawWorkItemData[]) => Object.values(subscribersRef.current).forEach(handlers => handlers.receiveWorkItemData?.(data)),
    [],
  );

  const todayHubMethods = useMemo<Method[]>(
    () => [
      { name: 'ReceiveTodaysAgentReportData', handler: receiveTodaysAgentData },
      { name: 'ReceiveTodaysQueueData', handler: receiveTodaysQueueData },
    ],
    [receiveTodaysAgentData, receiveTodaysQueueData],
  );

  useEffect(
    () => {
      if (queueIds.length > 0) {
        const todayHub = new SignalRHub({ baseUrl: `${apiBaseUrl}/aggregations/today/hub`, queryParams: { queueIds, agentIds: [userId] } }, token, todayHubMethods);
        todayHub.start();

        return () => { todayHub.stop(); };
      }
    },
    [queueIds, todayHubMethods, token, userId],
  );

  const liveHubMethods = useMemo<Method[]>(
    () => [
      { name: 'ReceiveQueueData', handler: receiveLiveQueueData },
      { name: 'ReceiveTenantAgentStates', handler: receiveLiveAgentData },
      { name: 'ReceiveWorkItemsWithConversations', handler: receiveWorkItemData },
    ],
    [receiveLiveAgentData, receiveLiveQueueData, receiveWorkItemData],
  );

  useEffect(
    () => {
      if (queueIds.length > 0) {
        const liveHub = new SignalRHub({ baseUrl: `${apiBaseUrl}/live/hub`, queryParams: { queueIds } }, token, liveHubMethods);
        liveHub.start();

        return () => { liveHub.stop(); };
      }
    },
    [liveHubMethods, queueIds, token],
  );

  return (
    <SignalRConnectionContext.Provider value={{ subscribe, unsubscribe, teams }}>
      {children}
    </SignalRConnectionContext.Provider>
  );
}