import { getCustomerFullName } from '../../models/customer.helpers';
import { ConversationInfo, RawWorkItemData } from '../models/reports.agents';

function getWorkItemInfoFromMainConversation({ conversations, primaryConversationId }: RawWorkItemData): ConversationInfo | null {
  if (conversations.length === 0)
    return null;

  if (conversations.length === 1) {
    return {
      conversationId: conversations[0].id,
      channelType: conversations[0].channelType,
      virtualConversationType: conversations[0].virtualConversationType ?? null,
      customerName: conversations[0].customer ? getCustomerFullName(conversations[0].customer) : 'unknown',
    };
  }

  const voiceConversations = conversations.filter(c => c.channelType === 'voice');
  if (voiceConversations.length === 1) {
    return {
      conversationId: voiceConversations[0].id,
      channelType: voiceConversations[0].channelType,
      virtualConversationType: voiceConversations[0].virtualConversationType ?? null,
      customerName: voiceConversations[0].customer ? getCustomerFullName(voiceConversations[0].customer) : 'unknown',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const primaryConversation = conversations.find(c => c.id === primaryConversationId!)!;
  return {
    conversationId: primaryConversation.id,
    channelType: primaryConversation.channelType,
    virtualConversationType: primaryConversation.virtualConversationType ?? null,
    customerName: primaryConversation.customer ? getCustomerFullName(primaryConversation.customer) : 'unknown',
  };
}

export default getWorkItemInfoFromMainConversation;