import { useLazyUpdateState } from '@buzzeasy/shared-frontend-utilities';
import { PropsWithChildren, ReactElement, createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { groupByProperty } from '../../../utils/arrayUtils';
import aggregateAgentsIntoTeam from '../../calculations/aggregateAgentsIntoTeam';
import { defaultAgentReport, processAgentLiveData, processAgentTodayData, processWorkItemData, toReportDictionary } from '../../calculations/teamCalculations';
import { ReportDictionary } from '../../models/reports';
import { AgentReport, RawAgentLiveData, RawAgentTodayData, RawWorkItemData, TeamReport } from '../../models/reports.agents';
import { SignalRConnectionContext } from './SignalRConnectionProvider';

function get(dictionary: ReportDictionary<AgentReport>, id: string): AgentReport {
  return dictionary[id] ?? defaultAgentReport(id);
}

export const TeamReportsContext = createContext<TeamReport[]>([]);

export default function TeamReportsProvider({ children }: PropsWithChildren): ReactElement {
  const { subscribe, unsubscribe, teams } = useContext(SignalRConnectionContext);

  const [agentsDict, setAgentsDict] = useLazyUpdateState<ReportDictionary<AgentReport>>({});
  const agentsDictRef = useRef(agentsDict);
  agentsDictRef.current = agentsDict;

  const receiveTodaysAgentData = useCallback(
    (data: RawAgentTodayData) => setAgentsDict(dict => {
      const agent = get(dict, data.id);
      const processed = processAgentTodayData(data);
      const merged: AgentReport = { ...agent, ...processed };

      return { ...dict, [merged.id]: merged };
    }),
    [setAgentsDict],
  );

  const receiveLiveAgentData = useCallback(
    (data: RawAgentLiveData[]) => setAgentsDict(dict => {
      const updatedReports = data.map(a => {
        const agent = get(dict, a.agentId);
        const processed = processAgentLiveData(a);
        return { ...agent, ...processed };
      });

      const updatedReportsDict = toReportDictionary(updatedReports);
      return { ...dict, ...updatedReportsDict };
    }),
    [setAgentsDict],
  );

  const receiveWorkItemData = useCallback(
    (data: RawWorkItemData[]) => {
      const groupedWorkItems = groupByProperty(data, wi => wi.agentId);
      const processedGroups = Object.map(groupedWorkItems, ([key, wIs]) => [key, wIs.map(processWorkItemData)]);

      setAgentsDict(dict => Object.map(dict, ([key, a]) => [key, { ...a, currentlyHandledWorkItems: processedGroups[a.id] ?? [] }]));
    },
    [setAgentsDict],
  );

  useEffect(
    () => {
      const subId = subscribe({ receiveTodaysAgentData, receiveLiveAgentData, receiveWorkItemData });
      return () => unsubscribe(subId);
    },
    [receiveLiveAgentData, receiveTodaysAgentData, receiveWorkItemData, subscribe, unsubscribe],
  );

  const [teamReports, setTeamReports] = useLazyUpdateState<TeamReport[]>([]);

  useEffect(
    () => {
      const interval = setInterval(
        () => setTeamReports(teams.map(t => aggregateAgentsIntoTeam(t, Object.values(agentsDictRef.current)))),
        3000,
      );

      return () => clearInterval(interval);
    },
    [setTeamReports, teams],
  );

  return (
    <TeamReportsContext.Provider value={teamReports}>
      {children}
    </TeamReportsContext.Provider>
  );
}