import { KeyValuePair, MediaType, MediaTypes, PartialReport, ReportBase, ReportDictionary, ReportingAgentStates } from '../models/reports';
import { AgentReport, HandledWorkItem, RawAgentLiveData, RawAgentTodayData, RawWorkItemData, WorkItemHandlingStats, WorkItemKpis } from '../models/reports.agents';
import getWorkItemInfoFromMainConversation from './getWorkItemInfoFromMainConversation';

export const defaultAgentReport = (id: string): AgentReport => ({
  id,
  tenantId: '',
  name: '',
  currentState: 'LoggedOut',
  breakName: null,
  currentStateSince: new Date(),
  timeSpentInStates: ReportingAgentStates.reduce((coll, state) => ({ ...coll, [state]: 0 }), {} as AgentReport['timeSpentInStates']),
  timeSpentInBreakNames: {},
  workItemsByMedia: MediaTypes.reduce((coll, media) => ({ ...coll, [media]: { handled: 0, missed: 0, avgHandlingTime: 0 } }), {} as AgentReport['workItemsByMedia']),
  workItemsByDispositionCode: {},
  currentlyHandledWorkItems: [],
  loggedInAt: null,
  loggedOutAt: null,
});

export const defaultWorkItemHandlingStats = (): WorkItemHandlingStats => ({
  handled: 0,
  missed: 0,
  avgHandlingTime: 0,
});

const reduceToObject = <TValue, TRes>(arr: KeyValuePair<TValue>[], selector: (value: TValue) => unknown = ((v) => v)): Record<string, TRes> =>
  arr.reduce((coll, { key, value }) => ({ ...coll, [key]: selector(value) }), {});

const getWorkItemHandlingStats = (kpis: KeyValuePair<WorkItemKpis>[], mediaType: MediaType): WorkItemHandlingStats => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const kpi = kpis.find(({ key }) => key === mediaType)!.value;
  return {
    handled: kpi.handledCount,
    missed: kpi.missedCount,
    avgHandlingTime: kpi.averageHandlingTimeInSeconds,
  };
};

export function processAgentTodayData(agentToday: RawAgentTodayData): PartialReport<AgentReport> {
  return {
    id: agentToday.id,
    ...(agentToday.name ? { name: agentToday.name } : {}),
    tenantId: agentToday.tenantId,
    timeSpentInStates: {
      ...(ReportingAgentStates.reduce((coll, key) => ({ ...coll, [key]: 0 }), {} as AgentReport['timeSpentInStates'])),
      ...reduceToObject(agentToday.secondsSpentPerState),
    },
    timeSpentInBreakNames: reduceToObject(agentToday.secondsSpentPerBreakName),
    workItemsByMedia: MediaTypes.reduce((coll, media) => ({
      ...coll,
      [media]: agentToday.workItemKpisPerMediaType.find(({ key }) => key === media)
        ? getWorkItemHandlingStats(agentToday.workItemKpisPerMediaType, media)
        : defaultWorkItemHandlingStats(),
    }), {} as AgentReport['workItemsByMedia']),
    workItemsByDispositionCode: reduceToObject(agentToday.workItemKpisPerDispositionCode, val => val.handledCount),
    loggedInAt: agentToday.loggedInAt ? new Date(agentToday.loggedInAt) : null,
    loggedOutAt: agentToday.loggedOutAt ? new Date(agentToday.loggedOutAt) : null,
  };
}

export function processAgentLiveData(agentState: RawAgentLiveData): PartialReport<AgentReport> {
  return {
    id: agentState.agentId,
    ...(agentState.agentName ? { name: agentState.agentName } : {}),
    currentState: agentState.state,
    currentStateSince: new Date(agentState.stateSince),
    breakName: agentState.breakName,
  };
}

export function processWorkItemData(workItem: RawWorkItemData): HandledWorkItem {
  const mainConversationInfo = getWorkItemInfoFromMainConversation(workItem);

  return {
    primaryConversationId: mainConversationInfo?.conversationId ?? null,
    channelType: mainConversationInfo?.channelType ?? workItem.status,
    virtualConversationType: mainConversationInfo?.virtualConversationType ?? null,
    createdAt: new Date(workItem.createdAt),
    customerName: mainConversationInfo?.customerName ?? '',
    state: workItem.status,
  };
}

export function toReportDictionary<T extends ReportBase>(reports: T[]): ReportDictionary<T> {
  const dict: ReportDictionary<T> = {};
  reports.forEach(r => dict[r.id] = r);

  return dict;
}