import { useCallback, useMemo, useState } from 'react';
import { ManualAssignResult } from '../../../redux/apis/blenderApi';
import { WaitingConversationInfo } from '../../models/reports.queues';

export type WorkItemPickErrorState = Extract<ManualAssignResult, 'NotFound' | 'NotStaffed'>;

interface PersistTaskStatesInterface {
  errorStates: Record<WaitingConversationInfo['conversationId'], WorkItemPickErrorState | undefined>;
  setConversationErrorState(conversationId: WaitingConversationInfo['conversationId'], errorState: WorkItemPickErrorState): void;
}

export default function usePersistTaskStates(): PersistTaskStatesInterface {
  const [errorStates, setErrorStates] = useState({});

  const setConversationErrorState = useCallback<PersistTaskStatesInterface['setConversationErrorState']>(
    (conversationId, errorState) => setErrorStates(curr => ({ ...curr, [conversationId]: errorState })),
    [],
  );

  const returnValue = useMemo<PersistTaskStatesInterface>(
    () => ({
      errorStates,
      setConversationErrorState,
    }),
    [errorStates, setConversationErrorState],
  );

  return returnValue;
}