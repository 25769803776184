export type MediaGroup =
  | 'chat'
  | 'email'
  | 'voice'
  | 'virtual';

export type VirtualConversationType =
  | 'Callback'
  | 'Preview';

export type WorkItemState =
  | 'Active'
  | 'AfterWork'
  | 'Closed'
  | 'Lookup'
  | 'Preview';

export const ReportingAgentStates = [
  'Idle',
  'Break',
  'Busy',
  'LoggedOut',
] as const;
export type ReportingAgentStatus = typeof ReportingAgentStates[number];

export const MediaTypes = [
  'Email',
  'Messaging',
  'Voice',
  'Webchat',
] as const;
export type MediaType = typeof MediaTypes[number];

export interface ReportBase {
  readonly id: string;
  name: string;
}

export type KeyValuePair<T> = { key: string; value: T };
export type PartialReport<T extends ReportBase> = { id: ReportBase['id'] } & Partial<T>;
export type ReportDictionary<T extends ReportBase> = Record<ReportBase['id'], T>;

export interface ConversationCustomer {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface AgentInfo {
  id: string;
  name: string;
}

export interface CustomerInfo {
  id: string;
  name: string;
  identifier: string;
}