import { Method, SignalRHub } from '@buzzeasy/shared-frontend-utilities/signalr';
import { PropsWithChildren, ReactElement, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import environmentConfig from '../../../environmentConfig';
import { selectAuthInfo } from '../../../redux/authSlice';
import { CustomerConnection } from '../../models/websiteVistitors';
import { RawCustomerConnection, RawCustomerSiteInteraction, mapToInternalCustomerConnection, mapToInternalSiteInteraction } from './WebsiteVisitorsProvider.mappers';

const { apiBaseUrl } = environmentConfig;

interface StarConversationCommand {
  customerClientId: string;
  agentData: {
    agentId: string;
    agentName: string;
  };
}

export interface WebsiteVisitorsContextValue {
  customerConnections: CustomerConnection[];
  startConversation(cmd: StarConversationCommand): void;
}

export const WebsiteVisitorsContext = createContext<WebsiteVisitorsContextValue>({ customerConnections: [], startConversation: () => { } });

export default function WebsiteVisitorsProvider({ children }: PropsWithChildren): ReactElement {
  const { token } = useSelector(selectAuthInfo);

  const [customerConnections, setCustomerConnections] = useState<CustomerConnection[]>([]);
  const hubRef = useRef<SignalRHub>();

  const initializeCustomerConnections = useCallback(
    (cs: RawCustomerConnection[]) => setCustomerConnections(cs.map(mapToInternalCustomerConnection)),
    [],
  );

  const updateSiteInteraction = useCallback(
    (clientId: string, rawSiteInteraction: RawCustomerSiteInteraction) => setCustomerConnections(curr => {
      const siteInteraction = mapToInternalSiteInteraction(rawSiteInteraction);
      const isNewConnection = !curr.find(c => c.clientId === clientId);

      if (isNewConnection)
        return [...curr, { clientId, siteInteraction }];
      else
        return curr.map(c => c.clientId === clientId ? { ...c, siteInteraction } : c);
    }),
    [],
  );

  const removeCustomerConnection = useCallback(
    (clientId: string) => setCustomerConnections(curr => curr.filter(c => c.clientId !== clientId)),
    [],
  );

  const methods = useMemo<Method[]>(
    () => [
      { name: 'OnAgentConnected', handler: initializeCustomerConnections },
      { name: 'OnSiteInteractionAddedOrUpdated', handler: updateSiteInteraction },
      { name: 'OnCustomerDisconnected', handler: removeCustomerConnection },
    ],
    [initializeCustomerConnections, removeCustomerConnection, updateSiteInteraction],
  );

  useEffect(
    () => {
      const customerTrackingHub = new SignalRHub(`${apiBaseUrl}/customer-tracking/agents`, token, methods);
      hubRef.current = customerTrackingHub;
      customerTrackingHub.start();

      return () => { customerTrackingHub.stop(); };
    },
    [methods, token],
  );

  const startConversation = useCallback(
    (cmd: StarConversationCommand) => hubRef.current?.send('StartConversation', cmd),
    [],
  );

  const contextValue = useMemo(
    () => ({
      customerConnections,
      startConversation,
    }),
    [customerConnections, startConversation],
  );

  return (
    <WebsiteVisitorsContext.Provider value={contextValue}>
      {children}
    </WebsiteVisitorsContext.Provider>
  );
}