import { CustomerInfo, PartialReport } from '../models/reports';
import { QueueReport, QueueSegment, RawQueueLiveData, RawQueueTodayData, SegmentCustomer } from '../models/reports.queues';

export const defaultQueueReport = (id: string): QueueReport => ({
  id,
  name: '',
  workflowId: null,
  mediaGroup: null,
  slaThreshold: null,
  startOfToday: null,
  lwtStart: null,
  acceptedCount: 0,
  abandonedCount: 0,
  callbackRequestedCount: 0,
  callbackAcceptedCount: 0,
  callbackWaitingCount: 0,
  slaRatio: null,
  longestWaitTimeInSeconds: 0,
  avgWaitingTimeInSeconds: 0,
  queueTimedOutCount: 0,
  conversationsWaiting: [],
  conversationsConnected: [],
});

function getLwtStartTime(segments: QueueSegment[]): Date | null {
  if (segments.length > 0) {
    const queuedAtTimes = segments.map(s => new Date(s.queuedAt).getTime());
    const oldestSegmentTime = Math.min(...queuedAtTimes);
    return new Date(oldestSegmentTime);
  }

  return null;
}

const segmentCustomerToCustomerInfo = (customer?: SegmentCustomer): CustomerInfo | null => customer
  ? {
    id: customer.id,
    name: [customer.firstName, customer.middleName, customer.lastName].filter(n => n !== '').join(' ') || 'Unknown',
    identifier: customer.phoneNumber || customer.emailAddress || '',
  }
  : null;

export function processQueueTodayData(data: RawQueueTodayData): PartialReport<QueueReport> {
  const slaValid = data.handledCount || data.abandonedCount || data.callbackRequestedCount || data.callbackAcceptedCount;

  return {
    id: data.id,
    slaThreshold: data.sla,
    startOfToday: data.startOfToday ? new Date(data.startOfToday) : null,
    queueTimedOutCount: data.queueTimedOutCount,
    acceptedCount: data.handledCount,
    abandonedCount: data.abandonedCount,
    callbackRequestedCount: data.callbackRequestedCount,
    callbackAcceptedCount: data.callbackAcceptedCount,
    slaRatio: slaValid ? Math.max(data.answeredWithinSLARatio, 0) : null,
    longestWaitTimeInSeconds: data.longestWaitTimeInSeconds,
    avgWaitingTimeInSeconds: data.avgWaitingTimeInSeconds,
  };
}

export function processQueueLiveData(data: RawQueueLiveData): PartialReport<QueueReport> {
  return {
    id: data.id,
    name: data.name || 'N/A', // for deleted queues
    workflowId: data.workflowId,
    mediaGroup: data.mediaGroup,
    lwtStart: getLwtStartTime(data.queueSegments),
    callbackWaitingCount: data.queueSegments.filter(s => s.virtualConversationType === 'Callback').length,
    conversationsWaiting: data.queueSegments
      .map(qS => ({
        conversationId: qS.conversationId,
        routingMode: qS.routingMode,
        waitStartTime: new Date(qS.queuedAt),
        position: qS.position,
        virtualConversationType: qS.virtualConversationType,
        customer: segmentCustomerToCustomerInfo(qS.customer),
      }))
      .sortBy(c => c.position),
    conversationsConnected: data.agentSegments
      .map(aS => ({
        connectionStartTime: new Date(aS.createdAt),
        virtualConversationType: aS.virtualConversationType,
        customer: segmentCustomerToCustomerInfo(aS.customer),
        agents: aS.agents,
      }))
      .sortBy(aS => aS.connectionStartTime.getTime()),
  };
}